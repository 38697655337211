import fromUnixTime from 'date-fns/fromUnixTime';
import differenceInDays from 'date-fns/differenceInDays';
import Cookies from 'js-cookie';
import {
  ANALYTICS_IDENTITY,
  ANALYTICS_RESET,
  CHATWOOT_RESET,
  CHATWOOT_SET_USER,
} from '../../helper/scriptHelpers';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';

Cookies.defaults = { sameSite: 'Lax' };

export const getLoadingStatus = state => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = user => {
  window.bus.$emit(CHATWOOT_SET_USER, { user });
  window.bus.$emit(ANALYTICS_IDENTITY, { user });
};

// 向 GTM 推送已登录用户信息
export const setGTM = user => {
  if (!user || typeof user !== 'object') {
    return;
  }

  const {
    account_id,
    id: user_id,
    email: user_email,
    name: user_name,
    accounts = []
  } = user;

  const accountDetails = accounts.find(account => account.id === account_id) || {};
  const { name: account_name, plan_name: account_plan } = accountDetails;

  const dataLayerPushObject = {
    ...(account_id && { account_id }),
    ...(user_id && { user_id }),
    ...(user_email && { user_email }),
    ...(user_name && { user_name }),
    ...(account_name && { account_name }),
    ...(account_plan && { account_plan })
  };

  if (Object.keys(dataLayerPushObject).length > 0) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerPushObject);
  }
};

export const getHeaderExpiry = response =>
  fromUnixTime(response.headers.expiry);

export const setAuthCredentials = response => {
  const expiryDate = getHeaderExpiry(response);
  Cookies.set('cw_d_session_info', response.headers, {
    expires: differenceInDays(expiryDate, new Date()),
  });
  setUser(response.data.data, expiryDate);
};

export const clearBrowserSessionCookies = () => {
  Cookies.remove('cw_d_session_info');
  Cookies.remove('auth_data');
  Cookies.remove('user');
};

export const clearLocalStorageOnLogout = () => {
  LocalStorage.remove(LOCAL_STORAGE_KEYS.DRAFT_MESSAGES);
};

export const deleteIndexedDBOnLogout = async () => {
  const dbs = await window.indexedDB.databases();
  dbs.forEach(db => {
    window.indexedDB.deleteDatabase(db.name);
  });
};

export const clearCookiesOnLogout = () => {
  window.bus.$emit(CHATWOOT_RESET);
  window.bus.$emit(ANALYTICS_RESET);
  clearBrowserSessionCookies();
  clearLocalStorageOnLogout();
  const globalConfig = window.globalConfig || {};
  const logoutRedirectLink = globalConfig.LOGOUT_REDIRECT_LINK || '/';
  window.location = logoutRedirectLink;
};

export const parseAPIErrorResponse = error => {
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }
  if (error?.response?.data?.error) {
    return error?.response?.data?.error;
  }
  if (error?.response?.data?.errors) {
    return error?.response?.data?.errors[0];
  }
  return error;
};

export const throwErrorMessage = error => {
  const errorMessage = parseAPIErrorResponse(error);
  throw new Error(errorMessage);
};
